<template>
  <div class="mint">
    <h1>Mint NFT</h1>
    <eth-address class="bold big" :eth-address="ethAddress" />
    <div v-if="mediaData">
      <p>Artwork: {{ artworkTitle }}</p>
      <p>Content URL: <a :href="mediaData.tokenURI" target="_blank">{{ mediaData.tokenURI }}</a></p>
      <p>Metadata URL: <a :href="mediaData.metadataURI" target="_blank">{{ mediaData.metadataURI }}</a></p>
      <br/>
      <p>Latest Version:
        <a :href="latestVersion" target="_blank">
          {{ latestVersion }}
        </a>
      </p>
      <br/>
      <div>
        <label for="creator-share">Creator Share: </label>
        <input
          v-model="creatorShare"
          name="creator-share"
          type="number"
          min="0"
          max="100"
          step="0.5"
          placeholder="10"
        > %
        <p>This is the percentage you receive on secondary sales</p>
        <button @click="mint" class="mint__button">mint</button>
        <p><i>{{ mintFeedback }}</i></p>
        <a v-if="etherscanLink" :href="etherscanLink" target="_blank">
          view on etherscan
        </a>
        <br/>
        <div v-if="mintTx">
          <p>All set, you can now create an auction.</p>
          <router-link to="/create-auction">Create Auction</router-link>
        </div>
      </div>
    </div>
    <p v-else-if="!isLoaded.toMintMany">loading artworks to mint...</p>
    <p v-else>no artworks to mint</p>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, reactive, ref, watch } from 'vue'
import { getSigner, getZora, firestore } from '../services.js'
import {
  constructMediaData,
  constructBidShares,
  sha256FromBuffer,
} from '@zoralabs/zdk'
import { useStore } from "vuex";
import EthAddress from './EthAddress.vue'

// TODO: display metadata?

export default defineComponent({
  components: {
    EthAddress
  },
  setup() {

    // check user has any artworks to mint
    const store = useStore()
    const ethAddress = computed(() => store.state.user.account)

    const mediaData = ref()
    const latestVersion = ref()
    const artworkTitle = ref()
    const mintTx = ref()
    const etherscanLink = ref()
    const creatorShare = ref(10)

    // keep track of things loading
    const isLoaded = reactive({
      toMintMany: false,
      prepareMedia: false
    })

    const toMintMany = ref([])
    const loadToMint = async (ethAddress) => {
      console.log("ethAdress:", ethAddress)
      if(!ethAddress) return

      try{
        const toMintRef = firestore.collection("artworksToMint")
        const query = toMintRef.where("ethAddress", '==', ethAddress)
        const querySnapshot = await query.get()
        console.log(querySnapshot)
        if(querySnapshot.empty) return

        toMintMany.value = querySnapshot.docs.map(doc => doc.data())
      }
      catch (error){
        console.log("error while loading artworks to mint:", error)
      }
    }

    // fetch media from arweave
    const fetchURL = async (url) => {
      const resp = await fetch(url)
      return Buffer.from(await resp.text())
    }

    const getHash = async url => {
      const buffer = await fetchURL(url)
      return sha256FromBuffer(buffer)
    }

    const prepareMedia = async (mediaURL, metadataURL) => {

      // get hashes
      const contentHash = await getHash(mediaURL)
      const metadataHash = await getHash(metadataURL)

      const mediaData = constructMediaData(
        mediaURL,
        metadataURL,
        contentHash,
        metadataHash
      )

      // NOTE[@george]: I think this is broken on ZDK at least for arweave urls
      // always hashes uri to this '0x18b4cf64cb828da7b198868c84011b58cbe50165dbd54c047aed9cd266aae684'
      // isVerified.value = await isMediaDataVerified(mediaData.value, 100000)
      // if (!isVerified.value){
      //   throw new Error("MediaData not valid, do not mint", isVerified.value)
      // }

      // isVerified.value = true

      return mediaData
    }

    onMounted(async () => {
      const signer = await getSigner()
      const ethAddress = (await signer.getAddress()).toLowerCase()

      await loadToMint(ethAddress)
      if(!toMintMany.value.length) {
        console.log("no artworks to mint")
        isLoaded.toMintMany = true
        return
      }

      // TODO: allow to select which media to mint
      // display artworks to mint
      const { mediaURL, metadataURL } = toMintMany.value[0]
      mediaData.value = await prepareMedia(mediaURL, metadataURL)

      // TODO: should compute latest version from metadata or have it cached in database?
      latestVersion.value = toMintMany.value[0].latestVersion
      artworkTitle.value = toMintMany.value[0].title

      isLoaded.toMintMany = true
    })

    const bidShares = computed(() => {
      return constructBidShares(
        Number(creatorShare.value), // creator share
        Number(100 - creatorShare.value), // owner share
        0 // prevOwner share
      )
    })

    const mintFeedback = ref('')
    const mint = async () => {
      try{
        const zora = await getZora()

        mintFeedback.value = "please check metamask..."
        const tx = await zora.mint(mediaData.value, bidShares.value)

        mintFeedback.value = "waiting for confirmations..."
        etherscanLink.value = `https://etherscan.io/tx/${tx.hash}`
        await tx.wait(1)

        mintTx.value = tx
        mintFeedback.value = `Minted!`
      } catch(error) {
        mintFeedback.value = `ERROR: ${error.message}`
        console.log(error)
      }
    }

    return {
      ethAddress,
      mediaData,
      latestVersion,
      artworkTitle,
      creatorShare,
      mintFeedback,
      mint,
      mintTx,
      isLoaded,
      etherscanLink
    }
  },
})
</script>

<style lang="scss" scoped>
.mint{
  padding: $margin;
  &__button{
    @include plain-link;
    cursor: pointer;
    border-style: solid;
    border-width: $border-width;
    @extend %gradient-border;
    @extend %gradient-text;
    font-weight: bold;
    padding: $margin-small $margin-small * 2;
    margin-bottom: $margin;
    display: block;
    margin: $margin 0;
    font-size: 150%;

    &:hover{
      background: $gradient-highlight;
      background-clip: initial;
      -webkit-text-fill-color: initial;
      color: $primary-background;
    }
  }
}
</style>